import useClosureEscape from 'hooks/useClosureEscape';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const DEFAULT_PATTERN = '\\\\(\\d+)\\\\';
const DEFAULT_MAX_LENGTH = 200;

const INPUT_TYPES = new Set(['INPUT', 'TEXTAREA']);

export default function useKeySequenceListener({
  listening,
  sequence: {
    pattern = DEFAULT_PATTERN,
    maxLength = DEFAULT_MAX_LENGTH,
  },
  onMatch,
  allowInputFocus = false,
}) {
  if (typeof pattern !== 'string') throw new Error('pattern must be a string');
  const patternAsRegex = useMemo(() => new RegExp(pattern), [pattern]);

  const buffer = useRef('');

  const handleKeyDown = useClosureEscape((evt) => {
    // Ignore and clear buffer if currently focusing a field
    if (!allowInputFocus && document.activeElement && INPUT_TYPES.has(document.activeElement.tagName)) return buffer.current = '';

    const key = evt.key;
    buffer.current = (buffer.current + key).slice(-maxLength);
    
    const match = buffer.current.match(patternAsRegex);
    if (!match) return;

    // There is a match
    buffer.current = '';
    onMatch(match);
  });

  useEffect(() => {
    if (listening === false) {
      buffer.current = '';
      return;
    }

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [listening]);
}
